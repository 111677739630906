.info-window h1,
h2 {
  font-family: "Yeseva One", cursive;
  margin-top: 0;
  margin-bottom: 0;
}

.info-window h1 {
  font-weight: bold;
  font-size: 1.5em;
}

.info-window h2 {
  font-family: "Slabo 27px", serif;
  font-weight: bold;
  font-size: 1em;
}

.info-window {
  font-family: "Slabo 27px", serif;
  font-size: 1em;
  margin-bottom: 0;
}
